@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-Light.eot");
    src: url("fonts/SFProText-Light.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-Light.woff2") format("woff2"), url("fonts/SFProText-Light.woff") format("woff"),
        url("fonts/SFProText-Light.ttf") format("truetype"),
        url("fonts/SFProText-Light.svg#SFProText-Light") format("svg");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-Heavy.eot");
    src: url("fonts/SFProText-Heavy.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-Heavy.woff2") format("woff2"), url("fonts/SFProText-Heavy.woff") format("woff"),
        url("fonts/SFProText-Heavy.ttf") format("truetype"),
        url("fonts/SFProText-Heavy.svg#SFProText-Heavy") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-HeavyItalic.eot");
    src: url("fonts/SFProText-HeavyItalic.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-HeavyItalic.woff2") format("woff2"), url("fonts/SFProText-HeavyItalic.woff") format("woff"),
        url("fonts/SFProText-HeavyItalic.ttf") format("truetype"),
        url("fonts/SFProText-HeavyItalic.svg#SFProText-HeavyItalic") format("svg");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-BoldItalic.eot");
    src: url("fonts/SFProText-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-BoldItalic.woff2") format("woff2"), url("fonts/SFProText-BoldItalic.woff") format("woff"),
        url("fonts/SFProText-BoldItalic.ttf") format("truetype"),
        url("fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic") format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-LightItalic.eot");
    src: url("fonts/SFProText-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-LightItalic.woff2") format("woff2"), url("fonts/SFProText-LightItalic.woff") format("woff"),
        url("fonts/SFProText-LightItalic.ttf") format("truetype"),
        url("fonts/SFProText-LightItalic.svg#SFProText-LightItalic") format("svg");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-Bold.eot");
    src: url("fonts/SFProText-Bold.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-Bold.woff2") format("woff2"), url("fonts/SFProText-Bold.woff") format("woff"),
        url("fonts/SFProText-Bold.ttf") format("truetype"), url("fonts/SFProText-Bold.svg#SFProText-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-RegularItalic.eot");
    src: url("fonts/SFProText-RegularItalic.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-RegularItalic.woff2") format("woff2"),
        url("fonts/SFProText-RegularItalic.woff") format("woff"),
        url("fonts/SFProText-RegularItalic.ttf") format("truetype"),
        url("fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-SemiboldItalic.eot");
    src: url("fonts/SFProText-SemiboldItalic.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-SemiboldItalic.woff2") format("woff2"),
        url("fonts/SFProText-SemiboldItalic.woff") format("woff"),
        url("fonts/SFProText-SemiboldItalic.ttf") format("truetype"),
        url("fonts/SFProText-SemiboldItalic.svg#SFProText-SemiboldItalic") format("svg");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-MediumItalic.eot");
    src: url("fonts/SFProText-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-MediumItalic.woff2") format("woff2"),
        url("fonts/SFProText-MediumItalic.woff") format("woff"),
        url("fonts/SFProText-MediumItalic.ttf") format("truetype"),
        url("fonts/SFProText-MediumItalic.svg#SFProText-MediumItalic") format("svg");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-Medium.eot");
    src: url("fonts/SFProText-Medium.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-Medium.woff2") format("woff2"), url("fonts/SFProText-Medium.woff") format("woff"),
        url("fonts/SFProText-Medium.ttf") format("truetype"),
        url("fonts/SFProText-Medium.svg#SFProText-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-Regular.eot");
    src: url("fonts/SFProText-Regular.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-Regular.woff2") format("woff2"), url("fonts/SFProText-Regular.woff") format("woff"),
        url("fonts/SFProText-Regular.ttf") format("truetype"),
        url("fonts/SFProText-Regular.svg#SFProText-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("fonts/SFProText-Semibold.eot");
    src: url("fonts/SFProText-Semibold.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFProText-Semibold.woff2") format("woff2"), url("fonts/SFProText-Semibold.woff") format("woff"),
        url("fonts/SFProText-Semibold.ttf") format("truetype"),
        url("fonts/SFProText-Semibold.svg#SFProText-Semibold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Rounded";
    src: url("fonts/SFRounded-Bold.eot");
    src: url("fonts/SFRounded-Bold.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFRounded-Bold.woff2") format("woff2"), url("fonts/SFRounded-Bold.woff") format("woff"),
        url("fonts/SFRounded-Bold.ttf") format("truetype"), url("fonts/SFRounded-Bold.svg#SFRounded-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Rounded";
    src: url("fonts/SFRounded-Regular.eot");
    src: url("fonts/SFRounded-Regular.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFRounded-Regular.woff2") format("woff2"), url("fonts/SFRounded-Regular.woff") format("woff"),
        url("fonts/SFRounded-Regular.ttf") format("truetype"),
        url("fonts/SFRounded-Regular.svg#SFRounded-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Rounded";
    src: url("fonts/SFRounded-Medium.eot");
    src: url("fonts/SFRounded-Medium.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFRounded-Medium.woff2") format("woff2"), url("fonts/SFRounded-Medium.woff") format("woff"),
        url("fonts/SFRounded-Medium.ttf") format("truetype"),
        url("fonts/SFRounded-Medium.svg#SFRounded-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Rounded";
    src: url("fonts/SFRounded-Semibold.eot");
    src: url("fonts/SFRounded-Semibold.eot?#iefix") format("embedded-opentype"),
        url("fonts/SFRounded-Semibold.woff2") format("woff2"), url("fonts/SFRounded-Semibold.woff") format("woff"),
        url("fonts/SFRounded-Semibold.ttf") format("truetype"),
        url("fonts/SFRounded-Semibold.svg#SFRounded-Semibold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
