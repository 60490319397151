@import "../../themes/variables";

.tp-text-input {
    font-size: em(20);
    line-height: em(20);
    letter-spacing: -0.24px;
    border: 1px solid transparent;
    &.size-sm {
        min-height: 40px;
    }
    &:not([type="search"]):not(.size-sm) {
        min-height: 60px;
    }
    &:not([type="search"]) {
        @include radius(11px);
        padding-left: 37px;
        padding-right: 37px;
    }
    &-tutor {
        &[type="search"] {
            background-image: url("../../assets/images/search-icon-tutor.svg");
            background-color: $tutor-secondary !important;
            color: $tutor-primary;
            &::placeholder {
                color: $tutor-primary;
            }
        }
        border-color: $tutor-primary;
        &::placeholder {
            color: $tutor-secondary;
        }
        &:focus {
            border-color: $tutor-primary;
        }
    }
    &-student {
        &[type="search"] {
            background-image: url("../../assets/images/search-icon-student.svg");
            background-color: $student-secondary !important;
            color: $student-primary;
            &::placeholder {
                color: $student-primary;
            }
        }
        border-color: $student-primary;
        &::placeholder {
            color: $student-secondary;
        }
        &:focus {
            border-color: $student-primary;
        }
    }
    &-guardian {
        &[type="search"] {
            background-image: url("../../assets/images/search-icon-guardian.svg");
            background-color: $guardian-secondary !important;
            color: $guardian-primary;
            &::placeholder {
                color: $guardian-primary;
            }
        }
        border-color: $guardian-primary;
        &::placeholder {
            color: $guardian-secondary;
        }
        &:focus {
            border-color: $guardian-primary;
        }
    }
    & ~ .invalid-feedback {
        position: absolute;
        left: 0;
    }
    &[type="password"].with-icon {
        background: url("../../assets/images/lock-icon.svg") no-repeat 38.25px center $white;
        padding-left: 88px;
    }
    &[type="email"].with-icon {
        background: url("../../assets/images/envelop-icon.svg") no-repeat 38.25px center #fff;
        padding-left: 88px;
    }
    &[type="search"] {
        background-repeat: no-repeat;
        background-position: 17.75px center;
        max-width: 324px;
        @include radius(7px);
        padding: 11.5px 45px;
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
    }
    &.is-invalid {
        border-color: $red !important;
        &::placeholder {
            color: $red !important;
        }
    }
    &.not-found {
        color: $red;
        border-color: $red;
        &::placeholder {
            color: $red;
        }
    }
    &.icon-right {
        background-position: 95%;
    }
}

textarea {
    &.tp-text-input {
        padding-top: 16px !important;
        &.size-sm {
            min-height: 263px !important;
        }
        &.size-md {
            min-height: 296px !important;
        }
        &.size-lg {
            min-height: 427 !important;
        }
    }
}

.form-group {
    position: relative;
}
