@import "../../themes/variables";

.tp-card {
    &:not(&-sm) {
        @include radius(14px);
    }
    display: flex;
    flex-direction: column;
    position: relative;
    &-tutor {
        .counter {
            background-color: $tutor-primary;
        }
        .title {
            color: $tutor-primary;
        }
        &.card-resource {
            &:not(.selected) {
                .title {
                    background-color: $light-blue-2;
                    color: $tutor-primary;
                }
            }
            &.selected {
                .title {
                    background-color: $tutor-primary;
                }
            }
        }
    }
    &-student {
        &.card-resource {
            &:not(.selected) {
                .title {
                    background-color: $student-secondary;
                    color: $student-primary;
                }
            }
            &.selected {
                .title {
                    background-color: $student-primary;
                }
            }
        }
        .counter {
            background-color: $student-primary;
        }
        .title {
            color: $student-primary;
        }
    }
    &-guardian {
        &.card-resource {
            &:not(.selected) {
                .title {
                    background-color: $guardian-secondary;
                    color: $guardian-primary;
                }
            }
            &.selected {
                .title {
                    background-color: $guardian-primary;
                }
            }
        }
        .counter {
            background-color: $guardian-primary;
        }
        .title {
            color: $guardian-primary;
        }
    }
    &:not(.card-resource) {
        .title {
            font-family: "SF Pro Rounded";
        }
    }
    &:not(&-xss) {
        background-color: $gray-secondary;
        border: 1px solid $gray-secondary;
    }
    &:not(.card-subject):not(.card-subject-sm):not(.card-resource) {
        text-align: center;
    }
    .counter {
        right: $sm;
        top: $sm;
        width: $sm;
        height: $sm;
    }
    &-xl {
        justify-content: center;
        max-width: em(406);
        min-height: em(617);
        padding: $sm;
        svg {
            width: em(250);
            height: em(203);
        }
        .title {
            font-size: em(30);
            line-height: em(36);
            margin-top: $md;
        }
    }
    &-lg {
        justify-content: center;
        max-width: em(437);
        min-height: em(552);
        padding: $sm;
        svg {
            width: em(307);
            height: em(210);
        }
        .title {
            font-size: em(30);
            line-height: em(36);
            margin-top: $md;
        }
    }
    &-md {
        max-width: em(320);
        min-height: em(309);
        padding: $sm;
        svg {
            width: em(168);
            height: em(168);
        }
        .title {
            font-size: em(25);
            line-height: em(30);
        }
    }
    &-sm {
        justify-content: center;
        max-width: em(181);
        min-height: em(190);
        padding: $sm;
        @include radius(7px);
        svg {
            width: em(120);
            height: em(120);
        }
        .title {
            font-size: em(15);
            line-height: em(18);
            margin-top: $xs;
        }
    }
    &-xss {
        background-color: $white;
        max-width: em(91);
        min-height: em(92);
        padding: em(8) em(12) em(18);
        svg {
            width: em(40);
            height: em(40);
        }
        .title {
            font-size: em(15);
            line-height: em(18);
        }
    }
    &:not(&-xl):not(&-lg):not(&-sm):not(.card-resource) {
        .title {
            margin-top: auto;
        }
    }
    &.card-subject {
        &:not(&-sm) {
            max-width: em(320);
            min-height: em(309);
            padding: em(46) em(30) em(30) em(36);
            svg {
                width: em(100);
                height: em(100);
            }
        }
        .title {
            margin-left: auto;
            font-size: em(25);
            line-height: em(30);
        }
        &-sm {
            max-width: em(233);
            min-height: em(225);
            padding: em(42) em(16) em(16) em(47);
            svg {
                width: em(80);
                height: em(80);
            }
        }
    }
    &.card-resource {
        max-width: em(234);
        min-height: em(225);
        overflow: hidden;
        .image {
            position: relative;
            height: em(176);
            display: flex;
            svg {
                width: em(100);
                height: em(100);
                margin: auto;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            &:hover {
                &::before {
                    content: "";
                    background: url("../../assets/images/play-icon.svg") no-repeat center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .title {
            font-size: em(20);
            line-height: em(24);
            text-align: right;
            padding: em(12) em(16);
        }
        &.selected {
            .title {
                color: $white;
            }
        }
    }
}
