@import "../../themes/variables";

.tp-dashboard {
    .tp-network-tag {
        width: 14px;
        height: 14px;
        @include radius(50%);
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: em(10);
        font-family: "SF Pro Rounded";
        &-tutor {
            background-color: $tutor-primary;
        }
        &-student {
            background-color: $student-primary;
        }
        &-guardian {
            background-color: $guardian-primary;
        }
    }
}
