@import "../../themes/variables";

.video-player {
    .video-placeholder {
        @include radius(15px);
        background-color: $gray-secondary;
        min-height: 386px;
        max-width: 709px;
    }
    video {
        object-fit: cover;
    }
}
