@import "variables";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}
.h1,
h1 {
    font-size: em(30);
    line-height: 35.8px;
}
.h2,
h2 {
    font-size: em(25);
}
.h3,
h3 {
    font-size: em(20);
    line-height: 23.87px;
}
.h4,
h4 {
    font-size: em(16);
}
.h5,
h5 {
    font-size: em(15);
}
.h6,
h6 {
    font-size: em(12);
}
.fs-60 {
    font-size: em(60);
}

.fs-72 {
    font-size: em(72);
}
.fs-0 {
    font-size: 0;
}
.ff-sf-pro-rounded {
    font-family: "SF Pro Rounded";
}
.fw-600 {
    font-weight: 600;
}
.bg-clr-tutor-primary {
    background-color: $tutor-primary !important;
}
.bg-clr-tutor-secondary {
    background-color: $tutor-secondary;
}
.bg-clr-light-blue-2 {
    background-color: $light-blue-2;
}
.clr-tutor-primary {
    color: $tutor-primary;
}
.clr-student-primary {
    color: $student-primary;
}
.bg-clr-student-primary {
    background-color: $student-primary;
}
.bg-clr-student-secondary {
    background-color: $student-secondary;
}
.bg-clr-student-secondary-light {
    background-color: $student-secondary-light;
}
.clr-guardian-primary {
    color: $guardian-primary;
}
.bg-clr-guardian-primary {
    background-color: $guardian-primary;
}
.bg-clr-guardian-secondary {
    background-color: $guardian-secondary;
}
.bg-clr-guardian-secondary-light {
    background-color: $guardian-secondary-light;
}
.bg-clr-gray-secondary {
    background-color: $gray-secondary;
}
.clr-light-blue {
    color: $light-blue;
}
.clr-white {
    color: $white;
}
.clr-black {
    color: $black;
}
.clr-red {
    color: $red;
}
.clr-gray-dark-2 {
    color: $gray-dark-2;
}
.tp-main-heading {
    font-size: em(30);
    line-height: em(20);
    font-family: "SF Pro Rounded";
    font-weight: 600;
}
article {
    font-size: em(20);
    line-height: 30px;
    letter-spacing: -0.24px;
}
