@import "../../themes/variables";

.tp-default-layout {
    padding: em(32) 0 em(32) em(191);
    .earning-holder {
        z-index: 1;
        position: relative;
        @include radius(7px);
        background-color: $gray-secondary;
        padding: em(39) em(16) em(39.55);
        .earning-bg {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            z-index: -1;
        }
        min-height: em(308);
    }
    .content-inner-wrap {
        &:not(.size-sm) {
            max-width: calc(100% - #{em(157)});
        }
        &.size-sm {
            max-width: calc(100% - #{em(244)});
        }
        margin-left: em(83);
        .content-inner {
            @include radius(7px);
            &:not(.no-padding) {
                padding: $xl;
            }
            &.bg-clr-light {
                background-color: rgba($color: $gray-secondary, $alpha: 0.8);
            }
            &.bg-clr-dark {
                background-color: $gray-secondary;
            }
        }
    }
    .selected-reason {
        &.bdr-thin {
            img,
            svg {
                border-width: em(4);
            }
        }
        &:not(.bdr-thin) {
            img,
            svg {
                border-width: em(6);
            }
        }
        img,
        svg {
            border-style: solid;
            @include radius(50%);
        }
        &-tutor {
            img,
            svg {
                border-color: $tutor-primary;
            }
        }
        &-guardian {
            img,
            svg {
                border-color: $guardian-primary;
            }
        }
        &-student {
            img,
            svg {
                border-color: $student-primary;
            }
        }
    }
    .delete-reason-list-options,
    .report-reason-list-options {
        max-width: 662px;
        .delete-reason,
        .report-reason {
            margin-top: 2rem;
            @include radius(11px);
            padding: 1rem 2rem 1rem 2rem;
        }
    }
}
