@import "../../themes/variables";

.tp-radio-btn {
    input {
        @include radius(50%);
        width: 25px;
        height: 25px;
        appearance: none;
        border-width: 1px;
        border-style: solid;
    }
    &-tutor {
        input {
            border-color: $tutor-primary;
            &.checked {
                background-color: $tutor-primary;
            }
        }
    }
    &-guardian {
        input {
            border-color: $guardian-primary;
            &.checked {
                background-color: $guardian-primary;
            }
        }
    }
    &-student {
        input {
            border-color: $student-primary;
            &.checked {
                background-color: $student-primary;
            }
        }
    }
}
