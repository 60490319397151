@import "../../themes/variables";

.tp-settings {
    .change-email-wrap {
        max-width: 623px;
        margin: 0 auto;
        .invalid-feedback {
            text-align: left;
        }
    }

    .selected-reason-type {
        svg {
            border-width: em(3);
            border-style: solid;
            @include radius(50%);
        }
        &-tutor {
            svg {
                border-color: $tutor-primary;
            }
        }
        &-guardian {
            svg {
                border-color: $guardian-primary;
            }
        }
        &-student {
            svg {
                border-color: $student-primary;
            }
        }
    }
}

.tp-profile-settings {
    .profile-img {
        .img-wrap {
            width: max-content;
            margin: 0 auto $sm;
            position: relative;
            img {
                height: em(168);
                width: em(168);
                object-fit: cover;
                border-style: solid;
                border-width: em(12);
            }
            &-tutor {
                .edit-field {
                    background-color: $tutor-primary;
                }
                img {
                    border-color: $light-blue;
                }
            }
            &-student {
                .edit-field {
                    background-color: $student-primary;
                }
                img {
                    border-color: $student-secondary;
                }
            }
            &-guardian {
                .edit-field {
                    background-color: $guardian-primary;
                }
                img {
                    border-color: $guardian-secondary;
                }
            }
            .edit-field {
                bottom: 0;
                right: 0;
            }
        }
    }
    .field-wrap {
        position: relative;
        display: flex;
        .tp-creatable-dropdown {
            width: 100%;
            > div {
                &:nth-child(2) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    > div {
                        &:first-child {
                            padding-left: 10px;
                            > div {
                                &:first-child {
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-group {
            display: flex;
            align-items: center;
            width: 100%;
        }
        .edit-field {
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            z-index: 1;
        }
        label,
        .field-text {
            font-size: 1.25em;
            background-color: $white;
            height: 60px;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            display: flex;
            align-items: center;
            padding-left: 32px;
            min-width: fit-content;
            margin-bottom: 0px;
        }
        input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            padding-left: 10px;
            &:not(.editing) {
                pointer-events: none;
            }
            &:not(.status):not(.editing)::placeholder {
                color: $black;
            }
            &.status {
                &.active {
                    &::placeholder {
                        color: $guardian-primary;
                    }
                }
                &.inactive {
                    &::placeholder {
                        color: $red;
                    }
                }
            }
        }
        .pass-dot {
            font-size: em(8);
        }
    }
}

.tp-email-code-confirmation {
    .code-input-wrap {
        max-width: 446px;
        padding: $sm $lg;
        background-color: $white;
        @include radius(7px);
    }
}

@media screen and (max-width: 1400px) {
}
