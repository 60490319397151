@import "../../themes/variables";

.tp-progress-circle {
    &-low {
        .CircularProgressbar {
            .CircularProgressbar-path {
                stroke: url("#tp-wallet-circle-grad-low");
            }
        }
    }
    &:not(&-low) {
        .CircularProgressbar {
            .CircularProgressbar-path {
                stroke: url("#tp-wallet-circle-grad");
            }
        }
    }
    .CircularProgressbar {
        .CircularProgressbar-path {
            stroke-width: em(8);
        }
        .CircularProgressbar-trail {
            stroke: $white;
            -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
            stroke-width: em(8);
            // -webkit-filter: drop-shadow(inset 0px 4px 4px rgba(0, 0, 0, 0.1));
            // filter: drop-shadow(inset 0px 4px 4px rgba(0, 0, 0, 0.1));
        }
    }
    &-tutor {
    }
    &-student {
    }
    &-guardian {
    }
}
