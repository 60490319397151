@import "mixins";
@import "functions";

//Colors
$guardian-primary: #1ea99d;
$tutor-primary: #06729d;
$student-primary: #f8aa4b;
$guardian-secondary: #b6f1ec;
$guardian-secondary-light: #dff6f4;
$tutor-secondary: #b7daea;
$student-secondary: #fcddb8;
$student-secondary-light: #fff4e9;
$gray-primary: #f2f2f2;
$gray-secondary: #f7f8fc;
$gray-light: #f7f7f9;
$gray-dark: #686869;
$gray-light-2: #bdbdbd;
$gray-light-3: #e0e0e0;
$gray-dark-2: #828282;
$light-blue: #b7daea;
$light-blue-2: #ebf2f5;
$black: #000;
$white: #fff;
$red: #c43838;
$green: #30b69e;
$yellow: yellow;
$orange: #ff9900;

//Margins & Paddings
$xxl: 5rem; //80px
$xl: 4.5rem; //72px
$lg: 3.5rem; //56px
$md: 2.5rem; //40px
$sm: 2rem; //32px
$xs: 1rem; //16px
$xxs: 0.5rem; //8px

//Margins & Paddings
// $xxl: em(80); //80px
// $xl: em(72); //72px
// $lg: em(56); //56px
// $md: em(40); //40px
// $sm: em(32); //32px
// $xs: em(16); //16px
// $xxs: em(8); //8px
