@import "../../themes/variables";

.tp-knowledge-bank {
    .resource-holder {
        background-color: $gray-secondary;
        &-tutor {
            .resource-controls {
                background-color: $light-blue-2;
                .progess-wrap {
                    .progressbar {
                        input[type="range"] {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]::-webkit-slider-runnable-track {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]::-webkit-slider-thumb {
                            background-color: $light-blue;
                        }
                        input[type="range"]:focus::-webkit-slider-runnable-track {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]::-moz-range-track {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]::-moz-range-thumb {
                            background-color: $light-blue;
                        }
                        input[type="range"]::-ms-fill-lower {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]::-ms-fill-upper {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]::-ms-thumb {
                            background-color: $light-blue;
                        }
                        input[type="range"]:focus::-ms-fill-lower {
                            background-color: $tutor-primary;
                        }
                        input[type="range"]:focus::-ms-fill-upper {
                            background-color: $tutor-primary;
                        }
                    }
                }
            }
            &.resource-audio {
                .resource-audio-details {
                    background-color: $tutor-primary;
                }
            }
        }
        &-student {
            .resource-controls {
                background-color: $student-secondary;
                .progess-wrap {
                    .progressbar {
                        input[type="range"] {
                            background-color: $student-primary;
                        }
                        input[type="range"]::-webkit-slider-runnable-track {
                            background-color: $student-primary;
                        }
                        input[type="range"]::-webkit-slider-thumb {
                            background-color: $student-secondary;
                        }
                        input[type="range"]:focus::-webkit-slider-runnable-track {
                            background-color: $student-primary;
                        }
                        input[type="range"]::-moz-range-track {
                            background-color: $student-primary;
                        }
                        input[type="range"]::-moz-range-thumb {
                            background-color: $light-blue;
                        }
                        input[type="range"]::-ms-fill-lower {
                            background-color: $student-primary;
                        }
                        input[type="range"]::-ms-fill-upper {
                            background-color: $student-primary;
                        }
                        input[type="range"]::-ms-thumb {
                            background-color: $student-secondary;
                        }
                        input[type="range"]:focus::-ms-fill-lower {
                            background-color: $student-primary;
                        }
                        input[type="range"]:focus::-ms-fill-upper {
                            background-color: $student-primary;
                        }
                    }
                }
            }
            &.resource-audio {
                .resource-audio-details {
                    background-color: $student-primary;
                }
            }
        }
        &-guardian {
            .resource-controls {
                background-color: $guardian-secondary;
                .progess-wrap {
                    .progressbar {
                        input[type="range"] {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]::-webkit-slider-runnable-track {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]::-webkit-slider-thumb {
                            background-color: $guardian-secondary;
                        }
                        input[type="range"]:focus::-webkit-slider-runnable-track {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]::-moz-range-track {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]::-moz-range-thumb {
                            background-color: $light-blue;
                        }
                        input[type="range"]::-ms-fill-lower {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]::-ms-fill-upper {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]::-ms-thumb {
                            background-color: $guardian-secondary;
                        }
                        input[type="range"]:focus::-ms-fill-lower {
                            background-color: $guardian-primary;
                        }
                        input[type="range"]:focus::-ms-fill-upper {
                            background-color: $guardian-primary;
                        }
                    }
                }
            }
            &.resource-audio {
                .resource-audio-details {
                    background-color: $guardian-primary;
                }
            }
        }
        &.resource-audio {
            padding: em(76) em(76) em(60) em(77);
            .resource-audio-details {
                padding: em(44) em(66);
                min-height: em(494);
                .resource-audio-text {
                    font-size: em(15);
                    line-height: em(20);
                }
                .resource-audio-name {
                    font-size: em(40);
                    line-height: em(20);
                }
            }
        }
        .resource-controls {
            margin-top: em(31);
            padding-right: em(28);
            &.resource-audio-controls {
                @include radius(7px);
                overflow: hidden;
                height: 60px;
                .play-pause-btn {
                    margin-right: em(39);
                }
            }
            &.resource-video-controls {
                height: 44px;
                .play-pause-btn {
                    margin-right: em(45);
                }
            }
            .play-pause-btn {
                min-width: em(80);
            }
            .progess-wrap {
                .progressbar {
                    input[type="range"] {
                        width: 100%;
                        height: 6px;
                        @include radius(6px);
                        // background-color: $tutor-primary;
                        -webkit-appearance: none;
                    }
                    input[type="range"]:focus {
                        outline: none;
                    }
                    input[type="range"]::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 6px;
                        cursor: pointer;
                        // background-color: $tutor-primary;
                        @include radius(6px);
                    }
                    input[type="range"]::-webkit-slider-thumb {
                        height: 12px;
                        width: 12px;
                        @include radius(50%);
                        // background-color: $light-blue;
                        cursor: pointer;
                        -webkit-appearance: none;
                        margin-top: -4px;
                    }
                    // input[type="range"]:focus::-webkit-slider-runnable-track {
                    //     background-color: $tutor-primary;
                    // }
                    input[type="range"]::-moz-range-track {
                        width: 100%;
                        height: 6px;
                        cursor: pointer;
                        // background-color: $tutor-primary;
                        @include radius(6px);
                    }
                    input[type="range"]::-moz-range-thumb {
                        height: 12px;
                        width: 12px;
                        @include radius(50%);
                        // background-color: $light-blue;
                        cursor: pointer;
                    }
                    input[type="range"]::-ms-track {
                        width: 100%;
                        height: 6px;
                        cursor: pointer;
                    }
                    input[type="range"]::-ms-fill-lower {
                        // background-color: $tutor-primary;
                        @include radius(50%);
                    }
                    input[type="range"]::-ms-fill-upper {
                        // background-color: $tutor-primary;
                        @include radius(50%);
                    }
                    input[type="range"]::-ms-thumb {
                        margin-top: -4px;
                        height: 12px;
                        width: 12px;
                        @include radius(50%);
                        // background-color: $light-blue;
                        cursor: pointer;
                    }
                    // input[type="range"]:focus::-ms-fill-lower {
                    //     background-color: $tutor-primary;
                    // }
                    // input[type="range"]:focus::-ms-fill-upper {
                    //     background-color: $tutor-primary;
                    // }
                }
                .progres-time {
                    margin-top: 3px;
                    color: $gray-light-2;
                }
            }
        }
        &.resource-image {
            padding: em(32) em(30);
            img {
                height: em(657);
                width: 100%;
                object-fit: cover;
            }
        }
        &.resource-video {
            padding: em(34) em(30);
            video {
                object-fit: cover;
            }
        }
    }
    .action-info-about-resource {
        @include radius(7px);
        border-width: 1px;
        border-style: solid;
        background-color: $gray-light;
        padding: em(20);
        max-width: em(191);
        letter-spacing: -0.24px;
        .resource-tags {
            > * {
                width: fit-content;
                color: $white;

                margin-top: em(20);
                @include radius(3px);
                padding: em(4) em(2);
            }
        }
        &-tutor {
            border-color: $tutor-primary;
            .resource-tags {
                > * {
                    background-color: $tutor-primary;
                }
            }
        }
        &-student {
            border-color: $student-primary;
            .resource-tags {
                > * {
                    background-color: $student-primary;
                }
            }
        }
        &-guardian {
            border-color: $guardian-primary;
            .resource-tags {
                > * {
                    background-color: $guardian-primary;
                }
            }
        }
    }
}
