@import "../../themes/variables";

.profile-picture-crop {
    max-width: 329px;
    @include radius(15px);
    background-color: $gray-secondary;
    padding: 39px 39px 24px;
    ._3m334JNOLa1lrKCCrXnNDG {
        width: 251px !important;
        height: 249px !important;
        border-radius: 15px;
        border-color: transparent;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 213px;
            height: 213px;
            border: 4px solid $white;
            @include radius(50%);
            z-index: 1;
        }
        canvas {
            width: 100%;
            height: 100%;
        }
    }
    .Icon--picture {
        width: 23px;
        height: 23px;
        > * {
            display: none;
        }
        &._23VzqtDiBsdmyddgDcP2FF {
            background: url("../../assets/images/MinusIconRounded.svg") no-repeat center;
        }
        &._2JJI18xT4B0iIKyuZLMpk9 {
            background: url("../../assets/images/plusIconRounded.svg") no-repeat center;
        }
    }
    .GNSzzq7thIGJvQKQMnUF4 {
        max-width: 195.21px;
        margin: 0 auto;
        ._10Kns8R3VdHSGFrESIz-4B {
            width: 0px;
            visibility: hidden;
        }
    }
    ._1Ou1tAFc1VCD4TNa0XwE7z {
        padding: 0px 0px 23px;
    }
    ._1MKAF96uXJHSW-a1nWoriQ,
    ._34iiqkc-jTKb3N7DvLLkwC {
        width: 100%;
    }
    input[type="range"] {
        height: 24px;
    }
    input[type="range"]::-webkit-slider-thumb {
        height: 11px;
        width: 11px;
        background-color: $tutor-primary;
        border-color: $tutor-primary;
        top: 5px;
        position: relative;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        height: 3px;
        border-radius: 3px;
        background-color: $tutor-secondary;
    }
}
