@import "../../themes/variables";

.tp-verification-code-input-wrap {
    input {
        border-width: 1px !important;
        border-style: solid !important;
        @include radius(3px !important);
        color: $black;
        font-family: inherit;
        font-size: em(20);
        &:not(:nth-child(3)):not(:last-child) {
            margin-right: $xs;
        }
        &:nth-child(3) {
            margin-right: $md;
        }
    }
    &.error {
        input {
            caret-color: #eb5757 !important;
            border-color: #eb5757 !important;
        }
    }
    &-tutor {
        input {
            caret-color: $tutor-primary !important;
            border-color: $tutor-primary !important;
        }
    }
    &-student {
        input {
            caret-color: $student-primary !important;
            border-color: $student-primary !important;
        }
    }
    &-guardian {
        input {
            caret-color: $guardian-primary !important;
            border-color: $guardian-primary !important;
        }
    }
}
