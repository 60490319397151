@import "../../themes/variables";

.tp-todo-item {
    background: $gray-light;
    @include radius(15px 15px 15px 0px);
    padding: $xs $sm $xs $xs;
    &.todo-item-edit {
        min-height: 150px;
    }
    .todo-list-details {
        .todo-edit-form {
            .form-group {
                height: 70%;
            }
        }
    }
    &-border {
        border-width: 1px;
        border-style: solid;
        &-tutor {
            border-color: $tutor-primary;
        }
        &-student {
            border-color: $student-primary;
        }
        &-guardian {
            border-color: $guardian-primary;
        }
    }
}
