@import "../../themes/variables";

.tp-question-board {
    .question-details-wrap {
        background-color: $gray-light;
        padding: $sm;
        @include radius(15px);
        .question-details {
            img {
                margin-bottom: $sm;
            }
            p {
                font-size: em(20);
            }
            textarea {
                min-height: 427px;
                padding-top: 20px;
            }
            .answer-add-files-wrap {
                position: relative;
                .answer-add-files-input {
                    padding-bottom: em(230);
                }
                .answer-add-files-dropdown {
                    &.mlti {
                        max-height: em(230);
                        overflow-y: auto;
                        overflow-x: hidden;
                    }
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    .dropdown-trigger-wrap {
                        background-color: $gray-secondary;
                        padding: em(11) em(24.44) em(10) em(16);
                        @include radius(10px);
                        .file-holder {
                            &-tutor {
                                background-color: $tutor-secondary;
                            }
                            &-guardian {
                                background-color: $guardian-secondary;
                            }
                            &-student {
                                background-color: $student-secondary;
                            }
                            @include radius(10px);
                            width: 54px;
                            height: 51px;
                            margin-right: $xxs;
                            font-size: em(8);
                            line-height: em(10);
                            letter-spacing: -0.24px;
                            display: flex;
                            align-items: flex-end;
                            justify-content: center;
                            padding-bottom: em(5);
                        }
                        .trigger-icon {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%) rotate(180deg);
                            right: em(24.44);
                            svg {
                                width: em(28.12);
                                height: em(28.12);
                            }
                        }
                    }
                }
            }

            .answer-question-actions {
                .answer-question-actions-list {
                    svg {
                        &:not(:last-child) {
                            margin-right: calc(#{$xxs + $xs});
                        }
                    }
                }
            }
        }
    }
    .resource-selection-wrap {
        img {
            height: 176px;
        }
    }
}

@media screen and (min-width: 1400px) {
}
