@import "../../themes/variables";

.tp-slider {
    &-tutor {
        &.owl-carousel {
            .owl-nav {
                .owl-prev,
                .owl-next {
                    background-image: url("../../assets/images/arrow-left-tutor.svg");
                }
            }
        }
    }
    &-student {
        &.owl-carousel {
            .owl-nav {
                .owl-prev,
                .owl-next {
                    background-image: url("../../assets/images/arrow-left-student.svg");
                }
            }
        }
    }
    &-guardian {
        &.owl-carousel {
            .owl-nav {
                .owl-prev,
                .owl-next {
                    background-image: url("../../assets/images/arrow-left-guardian.svg");
                }
            }
        }
    }
    &.owl-carousel {
        .owl-nav {
            .owl-prev,
            .owl-next {
                position: absolute;
                width: 12.5px;
                height: 25px;
                font-size: 0;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 99;
                top: 50%;
            }
            .owl-prev {
                left: -$md;
                transform: translateY(-50%);
            }
            .owl-next {
                right: -$md;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}
