@import "../../themes/variables";

.tp-verification-code-confirmation {
    .confirmation-wrap {
        max-width: 635px;
        background-color: $gray-secondary;
        @include radius(7px);
        padding: $xl;
    }
    .tp-verification-code-input-wrap {
        max-width: 410px;
    }
}

.tp-confirm-networks {
    .user-info-wrap {
        .avatar-img {
            img {
                height: em(180);
                width: em(180);
                object-fit: cover;
            }
        }
    }
}

.tp-network-details {
    .networks-info-wrap {
        max-width: 649px;
        .network-info {
            .avatar-img {
                img {
                    height: em(180);
                    width: em(180);
                    object-fit: cover;
                }
            }
            .row-wrap {
                .tp-btn-add {
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                    z-index: 1;
                }
                > .row {
                    &:last-of-type {
                        input {
                            max-width: 317px;
                        }
                    }
                    &:not(:last-of-type) {
                        margin-bottom: $sm;
                    }
                }
            }
        }
    }
}
