@import "../../themes/variables";

.tp-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    background-color: transparent;
    font-family: "SF Pro Rounded";
    &-primary {
        font-size: em(15);
        line-height: 17.9px;
        @include radius(7px);
        min-width: em(204);
        padding-top: 11.5px;
        padding-bottom: 11.5px;
        &-tutor {
            color: $white;
            border-color: $tutor-primary;
            background-color: $tutor-primary;
        }
        &-student {
            color: $white;
            border-color: $student-primary;
            background-color: $student-primary;
        }
        &-guardian {
            color: $white;
            border-color: $guardian-primary;
            background-color: $guardian-primary;
        }
        &-inverse {
            @extend .tp-btn-primary;
            &-tutor {
                border-color: $tutor-primary;
                color: $tutor-primary;
            }
            &-student {
                border-color: $student-primary;
                color: $student-primary;
            }
            &-guardian {
                border-color: $guardian-primary;
                color: $guardian-primary;
            }
        }
    }
    &-secondary {
        @extend .tp-btn-primary;
        background-color: $gray-light;
        .counter {
            width: 14px;
            height: 14px;
            font-size: em(11);
            margin-left: $xxs;
        }
        &-border {
            &-tutor {
                border-color: $tutor-primary;
                color: $tutor-primary;
            }
            &-student {
                border-color: $student-primary;
                color: $student-primary;
            }
            &-guardian {
                border-color: $guardian-primary;
                color: $guardian-primary;
            }
        }
        &-tutor {
            color: $tutor-primary;
            .counter {
                background-color: $tutor-primary;
            }
        }
        &-student {
            color: $student-primary;
            .counter {
                background-color: $student-primary;
            }
        }
        &-guardian {
            color: $guardian-primary;
            .counter {
                background-color: $guardian-primary;
            }
        }
    }
    &-back {
        display: inline-flex;
        align-items: center;
        font-size: em(24);
        line-height: 20px;
        &-tutor {
            color: $tutor-primary;
        }
        &-student {
            color: $student-primary;
        }
        &-guardian {
            color: $guardian-primary;
        }
        svg {
            width: em(30);
            height: em(30);
            margin-right: em(13);
        }
    }
    &-add {
        min-height: 60px;
        background-color: $white;
        &:not(&-md) {
            @include radius(11px);
            svg {
                width: em(40);
                height: em(40);
            }
        }
        &:not(&-lg):not(&-md) {
            min-width: 87px;
        }
        &-lg {
            min-width: 437px;
        }
        &-md {
            min-width: 204px;
            min-height: 43px;
            @include radius(7px);
            svg {
                width: em(16);
                height: em(16);
                margin-right: $xs;
            }
        }
        &-tutor {
            border-color: $tutor-primary;
        }
        &-student {
            border-color: $student-primary;
        }
        &-guardian {
            border-color: $guardian-primary;
        }
    }
}
