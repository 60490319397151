@import "../../themes/variables";

.tp-toggle-switch-btn {
    &-tutor {
        label {
            &:before {
                background-color: $tutor-primary;
            }
        }
        input[type="checkbox"] {
            &.switched + label {
                background-color: $tutor-secondary;
            }
        }
    }
    &-student {
        label {
            &:before {
                background-color: $student-primary;
            }
        }
        input[type="checkbox"] {
            &.switched + label {
                background-color: $student-secondary;
            }
        }
    }
    &-guardian {
        label {
            &:before {
                background-color: $guardian-primary;
            }
        }
        input[type="checkbox"] {
            &.switched + label {
                background-color: $guardian-secondary;
            }
        }
    }
    input[type="checkbox"] {
        visibility: hidden;
        &.switched + label {
            transform: rotate(360deg);
            &:before {
                transform: translateX(20px);
            }
        }
    }
    input[type="checkbox"] {
        &:not(.switched) + label {
            background-color: $gray-primary;
        }
    }
    label {
        display: flex;
        width: 52px;
        height: 32px;
        @include radius(32px);
        position: relative;
        transition: transform 0.75s ease-in-out;
        transform-origin: 50% 50%;
        cursor: pointer;
        &:before {
            transition: transform 0.75s ease;
            transition-delay: 0.5s;
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            @include radius(50%);
            top: 4px;
            left: 4px;
            @include shadow(0px 1px 1px rgba($color: $black, $alpha: 0.24));
        }
    }
}
