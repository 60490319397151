@import "themes/main.scss";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.toast-container {
    font-family: "Poppins", sans-serif;
    padding: 0;
    box-shadow: none;
    background: none;
    font-weight: 100;
}
