@import "../../themes/variables";

.tp-question {
    background: $gray-light;
    @include radius(15px 15px 15px 0px);
    padding: $sm $xs $sm $sm;
    position: relative;
    &-border {
        .question-text {
            font-weight: 600;
        }
        &-tutor {
            .question-text {
                &::before {
                    background-color: $tutor-primary;
                }
            }
        }
        &-student {
            .question-text {
                &::before {
                    background-color: $student-primary;
                }
            }
        }
        &-guardian {
            .question-text {
                &::before {
                    background-color: $guardian-primary;
                }
            }
        }
        .question-text {
            &::before {
                content: "";
                position: absolute;
                width: 3px;
                top: $sm;
                left: calc(#{$sm/2} - 1px);
                height: calc(100% - (#{$sm + $sm}));
                @include radius(1.5px);
            }
        }
    }
    .question-details {
        .tooltip-info {
            position: relative;
            &::before {
                @include radius(7px);
                background-color: $light-blue;
                font-size: em(12);
                line-height: em(14);
                padding: $xxs $xs;
                position: absolute;
                min-width: max-content;
                text-align: center;
                bottom: 99%;
                right: 0;
                z-index: 999;
                visibility: hidden;
                opacity: 0;
                @include transition(0.3s);
            }
            &:hover {
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
            &.clarity {
                &::before {
                    content: "Clarity question";
                }
            }
            &.followup {
                &::before {
                    content: "Follow up question";
                }
            }
            &.urgent {
                &::before {
                    content: "Urgent question";
                }
            }
        }
        .post-date {
            color: $gray-dark;
        }
    }
}
