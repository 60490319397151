@import "../../themes/variables";

.top-navbar {
    .navbar-nav {
        li {
            .nav-link {
                padding-top: 0;
            }
            &.tp-menu-dropdown {
                .dropdown-menu {
                    right: 0;
                    .dropdown-item {
                        color: inherit;
                        background-color: transparent;
                    }
                }
                .status {
                    position: relative;
                    display: inline-block;
                    &:not(.active) {
                        &::after {
                            background-color: $gray-light-3;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        @include radius(50%);
                        right: 0;
                    }
                }
                &:not(.notification) {
                    &.show {
                        > a {
                            position: relative;
                            z-index: 9999;
                        }
                    }
                    .dropdown-menu {
                        background: #f7f8fc;
                        @include shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
                        @include radius(15px);
                        border: none;
                        .dropdown-item {
                            color: $black;
                            font-family: "SF Pro Rounded";
                        }
                    }
                }
                &.status {
                    .status {
                        &.active {
                            &::after {
                                background-color: $green;
                            }
                        }
                        &::after {
                            bottom: 0;
                        }
                    }
                    .dropdown-menu {
                        padding: em(126) em(14) em(32) em(32);
                        top: -12px;
                        // min-width: em(137);
                        font-size: em(12);

                        .dropdown-item {
                            display: flex;
                            align-items: center;
                        }
                        input {
                            &[type="checkbox"] {
                                margin-right: em(27);
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                appearance: none;
                                width: 14px;
                                height: 14px;
                                padding: 2px;
                                @include radius(50%);
                                &:not(.checked) {
                                    background-color: $gray-light-3;
                                }
                                &.checked {
                                    // background-color: $green;
                                    background: url("../../assets/images/check-icon-white.svg") no-repeat center $green;
                                }
                            }
                        }
                    }
                }
                &.setting {
                    .dropdown-menu {
                        padding: em(77) em(32) em(32);
                        top: -8px;
                        min-width: em(191);
                        font-size: em(13);
                    }
                }
                &.notification {
                    &-tutor {
                        .dropdown-menu {
                            border-color: $tutor-primary;
                        }
                    }
                    &-guardian {
                        .dropdown-menu {
                            border-color: $guardian-primary;
                        }
                    }
                    &-student {
                        .dropdown-menu {
                            border-color: $student-primary;
                        }
                    }
                    .dropdown-menu {
                        min-width: em(706);
                        @include radius(10px);
                        padding: em(32);
                    }
                    .status {
                        &.active {
                            &::after {
                                background-color: $red;
                            }
                        }
                        &::after {
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}
