@import "../../themes/variables";

.tp-dropdown-popup {
    &.size-xs {
        .dropdown-menu {
            @include radius(7px);
            min-width: em(82);
            padding: $xs;
        }
    }
    &.size-sm {
        .dropdown-menu {
            min-width: em(267);
        }
    }
    &.size-md {
        .dropdown-menu {
            min-width: em(420);
        }
    }
    &.size-lg {
        .dropdown-menu {
            min-width: em(442);
        }
    }
    &:not(.size-xs) {
        .dropdown-menu {
            padding: $sm;
        }
    }
    .dropdown-menu {
        border-width: 1px;
        border-style: solid;
    }
    &:not(.fancy-dropdown-popup):not(.size-xs) {
        .dropdown-menu {
            @include radius(15px);
        }
    }
    &-tutor {
        .dropdown-menu {
            border-color: $tutor-primary;
        }
        &.fancy-dropdown-popup {
            &.show {
                .tp-btn {
                    color: $tutor-primary !important;
                    svg {
                        path {
                            stroke: $tutor-primary;
                        }
                    }
                }
            }
        }
    }
    &-student {
        .dropdown-menu {
            border-color: $student-primary;
        }
        &.fancy-dropdown-popup {
            &.show {
                .tp-btn {
                    color: $student-primary !important;
                    svg {
                        path {
                            stroke: $student-primary;
                        }
                    }
                }
            }
        }
    }
    &-guardian {
        .dropdown-menu {
            border-color: $guardian-primary;
        }
        &.fancy-dropdown-popup {
            &.show {
                .tp-btn {
                    color: $guardian-primary !important;
                    svg {
                        path {
                            stroke: $guardian-primary;
                        }
                    }
                }
            }
        }
    }
    &.fancy-dropdown-popup {
        &.show {
            .dropdown-trigger {
                .tp-btn {
                    border-bottom-color: $gray-secondary;
                    background-color: $gray-secondary !important;
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    position: relative;
                    z-index: 1001;
                }
            }
        }
        .dropdown-menu {
            background-color: $gray-secondary;
            @include radius(11px 0px 11px 11px);
        }
    }
}
