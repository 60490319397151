@import "../../themes/variables";

.tp-notification-msg {
    min-height: em(81);
    &-tutor {
        &.unread {
            .notification-details {
                background-color: $light-blue-2;
                .notification-type {
                    .notification-type-holder {
                        background-color: $tutor-secondary;
                    }
                }
            }
        }
        .notification-action {
            background-color: $light-blue-2;
        }
    }
    &-student {
        &.unread {
            .notification-details {
                background-color: $student-secondary-light;
                .notification-type {
                    .notification-type-holder {
                        background-color: $student-secondary;
                    }
                }
            }
        }
        .notification-action {
            background-color: $student-secondary-light;
        }
    }
    &-guardian {
        &.unread {
            .notification-details {
                background-color: $guardian-secondary-light;
                .notification-type {
                    .notification-type-holder {
                        background-color: $guardian-secondary;
                    }
                }
            }
        }
        .notification-action {
            background-color: $guardian-secondary-light;
        }
    }
    &:not(.unread) {
        .notification-details {
            background-color: $gray-secondary;
            .notification-type {
                .notification-type-holder {
                    background-color: $white;
                }
            }
        }
    }
    .notification-details {
        padding: em(8) em(16);
        @include radius(7px);
        .notification-info {
            margin-left: em(38);
        }
        .notification-type {
            .notification-type-holder {
                @include radius(10px);
                width: 60px;
                height: 100%;
                display: flex;
                svg {
                    margin: auto;
                }
            }
        }
    }
    .notification-action {
        @include radius(7px);
        width: 74px;
        margin-left: em(16);
        display: flex;
        svg {
            margin: auto;
        }
    }
}
