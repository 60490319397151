@import "../../themes/variables";

.tp-wallet {
    .tp-wallet-card {
        background-color: $gray-secondary;
        @include radius(14px);
        max-width: em(406);
        min-height: em(507);
        padding: em(32);
        display: flex;
        flex-direction: column;
        .tp-progress-circle {
            max-width: em(278);
            margin: auto;
        }
        .title {
            text-align: center;
            margin-top: auto;
        }
    }
}
