@import "../../themes/variables";

.tp-progress-bar {
    .progress {
        height: em(32);
        @include radius(21.5px);
        background-color: $white;
        @include shadow(inset 0px 4px 4px rgba(0, 0, 0, 0.1));
        .progress-bar {
            @include radius(21.5px);
        }
    }
    &-tutor {
        .progress-bar {
            background-color: $tutor-primary;
        }
    }
    &-student {
        .progress-bar {
            background-color: $student-primary;
        }
    }
    &-guardian {
        .progress-bar {
            background-color: $guardian-primary;
        }
    }
}
