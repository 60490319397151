@import "fonts";
@import "variables";
@import "typography";

body {
    font-size: 16px;
    font-family: "SF Pro Text", sans-serif;
    color: $black;
}

.bg-shape {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    &:not(.bg-tutor-primary) {
        height: 100vh;
    }
}

.text-trim {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tp-loader {
    .sr-only {
        font-size: 0;
    }
}

.pointer {
    cursor: pointer;
}

.objfit-cover {
    object-fit: cover;
}

.sep-1px {
    height: 1px;
    position: relative;
    left: -2rem;
    display: block;
    width: calc(100% + 4rem);
    margin: $sm 0;
}

.resize-none {
    resize: none;
}
.text-webkit-center {
    text-align: -webkit-center;
}
.full-height {
    min-height: 100vh;
    padding: $sm 0;
    &.content-centered {
        display: flex;
        align-items: center;
    }
}

.transform-none {
    transform: none !important;
}

.bdr-0 {
    border-color: transparent !important;
}

.minw-100 {
    min-width: 100% !important;
}

.thumbnail-rounded {
    max-width: em(150);
    height: em(150);
    width: 100%;
    object-fit: cover;
    @include radius(50%);
}

.question-gallery-modal {
    .gallery-images {
        img {
            height: 490px;
            width: 48%;
            object-fit: cover;
        }
    }
}

.gallery-images {
    display: flex;
    flex-wrap: wrap;
    img {
        &:not(:last-child) {
            margin-right: $xs;
        }
    }
}
.tp-package-card {
    background-color: $gray-secondary;
    @include radius(15px);
    max-width: em(485);
    min-height: em(549);
    padding: em(32);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &-tutor {
        .pakage-price-tag {
            background-color: $tutor-primary;
            &::after {
                border-top-color: $tutor-primary;
            }
        }
    }
    &-student {
        .pakage-price-tag {
            background-color: $student-primary;
            &::after {
                border-top-color: $student-primary;
            }
        }
    }
    &-guardian {
        .pakage-price-tag {
            background-color: $guardian-primary;
            &::after {
                border-top-color: $guardian-primary;
            }
        }
    }
    .pakage-price-tag {
        padding: em(10);
        position: absolute;
        left: em(56);
        min-width: em(56);
        top: 0;
        &::after {
            position: absolute;
            content: "";
            left: 0;
            width: 0;
            height: 0;
            top: 100%;
            border-left: em(28) solid transparent;
            border-right: em(28) solid transparent;
            border-top-width: em(28);
            border-top-style: solid;
        }
    }
    .package-image {
        position: relative;
        .package-img-text {
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -40%);
        }
    }
}
.uploaded-resource {
    max-width: 222px;
    padding: $xs;
    img {
        width: 87px;
        height: 87px;
    }
    &-tutor {
        background-color: $light-blue-2;
    }
    &-student {
        background-color: $student-secondary;
    }
    &-guardian {
        background-color: $guardian-secondary;
    }
}

.resource-upload-holder {
    padding: em(32) em(72) em(32) em(72);
    background-color: $gray-secondary;
    @include radius(7px);
    .resource-upload-view {
        @include radius(7px);
        object-fit: cover;
        width: 100%;
        height: em(512);
    }
}

.tp-slider {
    &.tp-center-mode-active-big {
        .center {
            .tp-wallet-card,
            .tp-card {
                transform: scale(1.2);
                margin-bottom: 60px;
            }
            .tp-card {
                &-tutor {
                    background-color: $tutor-secondary;
                }
                &-student {
                    background-color: $student-secondary;
                }
                &-guardian {
                    background-color: $guardian-secondary;
                }
            }
        }
    }
}

.scrolling-container {
    &.maxh-35 {
        max-height: 35vh;
    }
    &:not(.maxh-35) {
        max-height: 60vh;
    }
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: $sm;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        @include radius(5px);
    }
    &::-webkit-scrollbar-thumb {
        @include radius(5px);
    }
    &-tutor {
        &::-webkit-scrollbar-thumb {
            background-color: $tutor-primary;
        }
    }
    &-student {
        &::-webkit-scrollbar-thumb {
            background-color: $student-primary;
        }
    }
    &-guardian {
        &::-webkit-scrollbar-thumb {
            background-color: $guardian-primary;
        }
    }
}

.net-earning-info-popup {
    .net-earning-info-popup-trigger {
        position: relative;
        &:hover {
            .net-earning-info-popup-content {
                visibility: visible;
                opacity: 1;
            }
        }
        .net-earning-info-popup-content {
            line-height: 30px;
            text-align: left;
            right: 0;
            top: 99%;
            z-index: 99;
            visibility: hidden;
            opacity: 0;
            @include transition(0.3s);
            min-width: em(201);
            padding: em(8) em(9) em(2) em(17);
            position: absolute;
            @include radius(7px 7px 7px 0px);
            border-width: 1px;
            border-style: solid;
            background-color: $gray-secondary;
        }
    }
    &-tutor {
        .net-earning-info-popup-trigger {
            .net-earning-info-popup-content {
                border-color: $tutor-primary;
            }
        }
    }
    &-student {
        .net-earning-info-popup-trigger {
            .net-earning-info-popup-content {
                border-color: $student-primary;
            }
        }
    }
    &-gaurdian {
        .net-earning-info-popup-trigger {
            .net-earning-info-popup-content {
                border-color: $guardian-primary;
            }
        }
    }
}

.tp-table {
    font-size: em(20);
    letter-spacing: -0.24px;
    &:not(.style-2) {
        @include radius(7px);

        th {
            padding: em(32);
        }
        tr {
            border-top-width: 1.5px;
        }
    }
    &.style-2 {
        @include radius(15px 15px 0px 0px);
        th {
            padding: em(20) em(16) em(13) em(30);
        }
        tr {
            border-top-width: 3px;
        }
    }
    tr {
        border-color: $light-blue-2;
        border-style: solid;
    }
    th {
        font-family: "SF Pro Rounded";
        line-height: em(20);
        font-weight: 500;
        svg {
            &.arrow-down {
                transform: rotate(90deg);
            }
        }
    }
    td {
        padding: em(16);
        line-height: 24px;
        &.status {
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                width: 9px;
                height: 9px;
                @include radius(50%);
                content: "";
                display: block;
                margin-right: $xs;
                background-color: $gray-primary;
            }
            &.red {
                &::before {
                    background-color: $red;
                }
            }
            &.green {
                &::before {
                    background-color: $green;
                }
            }
            &.yellow {
                &::before {
                    background-color: $yellow;
                }
            }
        }
    }
}

$spacing: (
    (ml, margin-left),
    (mt, margin-top),
    (mb, margin-bottom),
    (mr, margin-right),
    (pl, padding-left),
    (pt, padding-top),
    (pb, padding-bottom),
    (pr, padding-right)
);

@each $item in $spacing {
    @include generateSpacing(nth($item, 1), nth($item, 2));
}

@include generateWH("tpw", "width");

@include generateWH("tph", "height");

@include generateRadius("radius");

@media screen and (max-width: 1400px) {
    html,
    body {
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .guardian-bg {
        > * {
            display: none;
        }
        background: url("../assets/images/guardian-bg-mob.svg") no-repeat right top/cover;
    }
}
