@import "../../themes/variables";

.tp-modal {
    &-tutor {
        .modal-content {
            border-color: $tutor-primary;
        }
    }
    &-guardian {
        .modal-content {
            border-color: $guardian-primary;
        }
    }
    &-student {
        .modal-content {
            border-color: $student-primary;
        }
    }
    .modal-content {
        @include radius(10px);
        .modal-body {
            padding: $xl;
            .close-btn {
                top: $sm;
                right: $sm;
                position: absolute;
                z-index: 1;
            }
        }
    }
    &.size-1 {
        max-width: em(455);
    }
    &.size-2 {
        max-width: em(584);
    }
    &.size-3 {
        max-width: em(637);
    }
    &.size-4 {
        max-width: em(723);
    }
    &.size-5 {
        max-width: em(737);
    }
    &.size-6 {
        max-width: em(787);
    }
    &.size-7 {
        max-width: em(962);
    }
    &.size-8 {
        max-width: em(990);
    }
    &.size-9 {
        max-width: em(1039);
    }
    &.size-10 {
        max-width: em(1052);
    }
    &.size-11 {
        max-width: em(1342);
    }
    &.size-12 {
        max-width: em(1453);
    }
}
