@import "../../themes/variables";

.tp-choose-user-type {
    .logo {
        svg {
            width: em(321);
            height: em(67);
        }
    }
}
.tp-signup-terms {
    .list-wrap {
        max-width: 838px;
    }
}

.tp-student-invite-form {
    .sub-title {
        margin-bottom: -40px;
    }
    .subject-select-dropdown {
        .form-group,
        .input {
            max-width: 437px;
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 1400px) {
}
