@import "../../themes/variables";

.tp-creatable-dropdown {
    &-tutor {
        &.selected,
        &.bg-fill {
            > div {
                &:nth-child(2) {
                    > div {
                        &:first-child {
                            > div {
                                &:first-child {
                                    color: $tutor-primary;
                                }
                            }
                        }
                    }
                }
            }
        }
        &:not(.bdr-none) {
            > div {
                &:nth-child(2) {
                    border-color: $tutor-primary;
                }
                &:nth-child(3) {
                    border-color: transparent $tutor-primary $tutor-primary $tutor-primary;
                }
            }
        }
        > div {
            &:nth-child(2) {
                &:hover {
                    border-color: $tutor-primary;
                }
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                color: $tutor-secondary;
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                > div {
                    &::-webkit-scrollbar-thumb {
                        background-color: $tutor-secondary;
                    }
                }
            }
        }
    }
    &-student {
        &.selected,
        &.bg-fill {
            > div {
                &:nth-child(2) {
                    > div {
                        &:first-child {
                            > div {
                                &:first-child {
                                    color: $student-primary;
                                }
                            }
                        }
                    }
                }
            }
        }
        &:not(.bdr-none) {
            > div {
                &:nth-child(2) {
                    border-color: $student-primary;
                }
                &:nth-child(3) {
                    border-color: transparent $student-primary $student-primary $student-primary;
                }
            }
        }
        > div {
            &:nth-child(2) {
                &:hover {
                    border-color: $student-primary;
                }
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                color: $student-secondary;
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                > div {
                    &::-webkit-scrollbar-thumb {
                        background-color: $student-secondary;
                    }
                }
            }
        }
    }
    &-guardian {
        &.selected,
        &.bg-fill {
            > div {
                &:nth-child(2) {
                    > div {
                        &:first-child {
                            > div {
                                &:first-child {
                                    color: $guardian-secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
        &:not(.bdr-none) {
            > div {
                &:nth-child(2) {
                    border-color: $guardian-primary;
                }
                &:nth-child(3) {
                    border-color: transparent $guardian-primary $guardian-primary $guardian-primary;
                }
            }
        }
        > div {
            &:nth-child(2) {
                &:hover {
                    border-color: $guardian-primary;
                }
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                color: $guardian-secondary;
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                > div {
                    &::-webkit-scrollbar-thumb {
                        background-color: $guardian-secondary;
                    }
                }
            }
        }
    }
    // &.selected,
    // &.bg-fill {
    //     > div {
    //         &:nth-child(2) {
    //             > div {
    //                 &:first-child {
    //                     > div {
    //                         &:first-child {
    //                             color: $tutor-primary;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    max-width: 437px;
    &.bg-fill {
        > div {
            &:nth-child(2) {
                background-color: $gray-secondary;
            }
            &:nth-child(3) {
                background-color: $gray-secondary;
                > div {
                    > div {
                        background-color: $gray-secondary;
                    }
                }
            }
        }
    }
    &.disabled {
        > div {
            &:nth-child(2) {
                background-color: $gray-primary;
                &:nth-child(2) {
                    > div {
                        &:first-child {
                            > div {
                                &:first-child {
                                    color: $gray-dark;
                                }
                            }
                        }
                    }
                }
            }
            &:nth-child(3) {
                background-color: $gray-primary;
                > div {
                    > div {
                        background-color: $gray-primary;
                    }
                }
            }
        }
    }
    &:not(.bg-fill):not(.disabled) {
        > div {
            &:nth-child(2) {
                background-color: $white;
            }
            &:nth-child(3) {
                background-color: $white;
                > div {
                    > div {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.radius-thin {
        > div {
            &:nth-child(2) {
                @include radius(7px);
            }
            &:nth-child(3) {
                @include radius(0 0 7px 7px);
            }
        }
    }
    &:not(.radius-thin) {
        > div {
            &:nth-child(2) {
                @include radius(11px);
            }
            &:nth-child(3) {
                @include radius(0 0 11px 11px);
            }
        }
    }
    &.dropdown-size-sm {
        > div {
            min-height: 40px;
        }
    }
    &:not(.dropdown-size-sm) {
        > div {
            min-height: 60px;
        }
    }
    &.bdr-none {
        > div {
            &:nth-child(2) {
                border-color: transparent;
                &:hover {
                    border-color: transparent;
                }
            }
            &:nth-child(3) {
                border-color: transparent;
            }
        }
    }
    &:not(.bdr-none) {
        > div {
            &:nth-child(3) {
                border-width: 1px;
                border-style: solid;
            }
        }
    }
    &.arrow-right {
        &:not(.active) {
            > div {
                &:nth-child(2) {
                    > div {
                        &:last-child {
                            > svg {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }
        }
    }
    &.arrow-open-up {
        &.active {
            > div {
                &:nth-child(2) {
                    > div {
                        &:last-child {
                            > svg {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }
        }
    }
    > div {
        &:nth-child(2) {
            box-shadow: none;
            > div {
                &:first-child {
                    padding-left: 33px;
                    > div {
                        &:first-child {
                            font-size: em(20);
                            line-height: em(20);
                            letter-spacing: -0.24px;
                            overflow: initial;
                            margin-left: 0;
                            cursor: text;
                            // color: $tutor-secondary;
                        }
                    }
                }
                &:last-child {
                    > span {
                        display: none;
                    }
                    > svg {
                        margin-right: 22.5px;
                    }
                }
            }
        }
        &:nth-child(3) {
            margin-top: -10px;
            box-shadow: none;
            padding-right: 22.5px;
            padding-bottom: 23px;
            z-index: 2;
            > div {
                max-height: 150px;
                padding: 0px 21px;
                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 11px;
                }
                > div {
                    cursor: pointer;
                    text-align: left;
                    line-height: 20px;
                    letter-spacing: -0.24px;
                    color: $black;
                    &:last-child {
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }
}
