@import "../../themes/variables";

.tp-side-navbar {
    left: 0;
    top: 0;
    z-index: 1;
    width: em(157);
    border-right: 1px solid #ededed;
    padding: em(32) em(21);
    .logo {
        margin-bottom: em(150);
    }
    .navbar-items-wrap {
        .nav-block {
            &:not(:last-child) {
                margin-bottom: em(66);
            }
            .nav-item {
                .nav-icon {
                    svg {
                        width: em(40);
                        height: em(40);
                    }
                }
                .nav-text {
                    font-size: em(13);
                    line-height: em(20);
                    margin-top: $xxs;
                }
            }
        }
    }
}
