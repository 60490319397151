@import "../../themes/variables";

.tp-accordion-tab {
    @include radius(7px);
    padding: em(20) em(32);
    &:not(.bg-clr-fill) {
        background: $white;
    }
    &.bg-clr-fill {
        background-color: $gray-secondary;
    }
    .tp-accordion-tab-title {
        .arrow-indicator {
            .arrow-down {
                transform: rotate(90deg);
            }
        }
    }
    .tp-accordion-tab-content {
        margin-top: $xs;
        line-height: 24px;
    }
}
